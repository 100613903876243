import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layouts/Layout"
import SEO from "../components/Seo"

const Page = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="grid place-items-center h-screen">
      <div className="text-center">
        <span className="text-xl font-bold mb-4 text-blue-700">404 ERROR</span>
        <h1 className="text-5xl font-bold mb-2">Page not found.</h1>
        <p className="text-gray-500 mb-4">Sorry, we coudn't find the page you were looking for.</p>
        <Link to="/">Go back Home</Link>
      </div>
    </div>
  </Layout>
)

export default Page
